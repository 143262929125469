.signature-field {
  background: #e9ecef;
  margin: 0 auto;
  height: 150px;
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 5px;

  .sigCanvas {
    width: 100%;
    height: 100%;
  }

  p {
    position: absolute;
    bottom: 5px;
    left: 10px;
    margin: 0;
    font-size: 14px;
    color: #afadad;
  }
}

.non-gray-scale {
  background-color: #fff;
}
