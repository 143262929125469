@import './components/progress_bar.scss';
@import './components/error_component.scss';
@import './components/loader.scss';
@import './components/modal.scss';
@import './fields/text_area_field.scss';
@import './fields/radio_button_field.scss';
@import './fields/signature_field.scss';
@import './fields/med_logo.scss';
@import './vendors/bootstrap.scss';

body {
  font-family: 'Lato' !important;
}

label {
  font-size: 0.8rem;
  display: inline-block;
  font-weight: bold;
  color: #6c757d;
}

.link {
  color: #0d6efd;
  color: var(--bs-link-color);
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.link-disabled,
.link-disabled:hover {
  color: #adb5bd;
  text-decoration: underline;
  cursor: not-allowed;
  border: none;
  background-color: transparent;
}

.add-remove-disable,
.add-remove-disable:hover {
  color: #adb5bd;
  cursor: not-allowed;
}

ul li::marker {
  color: #f4364c;
}

.nav-link.active {
  color: #fcbe4f !important;
  font-weight: bold;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label {
  opacity: 1;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #5c94c6;
}

.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
}

.location-form {
  font-style: normal;
  text-decoration: none;
}

.typeahead-container {
  position: absolute;
  top: calc(100% + 10px);
  background-color: #fff;
  width: calc(100% - var(--bs-gutter-x));
  min-width: 90px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  overflow: hidden;

  .typeahead-item {
    padding: 0.1rem 0.75rem;
    cursor: pointer;
    &:hover {
      background-color: #f8f9fa !important;
    }
  }
}
