.personal-assistant-container {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  hr {
    margin: 0;
  }

  .personal-assistant-icon {
    width: 50px;
    height: 50px;
  }
}
