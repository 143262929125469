.password-checklist-container {
  margin-top: 0px !important;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.password-checklist-element {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
}

.password-checklist-text {
  margin-left: 10px;
  width: 90%;
}
